import gql from "graphql-tag";

export const EXPENSE_TYPES = gql`
  query expenseTypes {
    expenseTypes {
      id
      name
    }
  }
`;

export const PAGINATE = gql`
  query FilterWithPaginateImprests($criteria: ImprestFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateImprests(criteria: $criteria, pagination: $pagination) {
      content {
        id
        period
        requestStatus
        requested {
          amount
          code
        }
        reason
        timestamp
        employee {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        travel {
          id
          beginDate
          endDate
          startWorkDate
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          address
          country
          city
          region
          requestStatus
        }
        expenseManagement {
          id
          name
          project {
            name
            color
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
      pageInfo {
        totalElements
      }
    }
  }
`;
